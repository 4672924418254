
import { Component, Prop, Vue } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import { Inject } from 'inversify-props';
import { Input } from 'element-ui';
import CarsCategoryManagerService, { CarsCategoryManagerServiceS } from '@/modules/cars-category-manager/cars-category-manager.service';

// @ts-ignore
import ModalWrapper, { DialogOptions } from '@/modules/common/components/modal-wrapper.vue';
import { ValidationError } from 'class-validator';
import ValidationErrorComponent from '@/modules/common/components/ui-kit/validation-error.vue';
import NewCategoryModel from '@/modules/cars-category-manager/models/new-category.model';

@Component({
    components: {
        ModalWrapper,
        ValidationErrorComponent,
        'el-input': Input,
    },
})
export default class AddCarCategoryPopup extends Vue {
    @Inject(CarsCategoryManagerServiceS) private carsCategoryManagerService!: CarsCategoryManagerService;

    @Prop({ required: false })
    private visible?: boolean;

    validationErrors: ValidationError[] | null = null;
    categoryName: string|null = null;

    close() {
        this.$emit('onClose');
    }

    async addEditCarCategory() {
        if (!this.categoryName) {
            return;
        }

        this.$emit('new-category', { name: this.categoryName });
    }
}
