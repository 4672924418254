
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import SippService, { SippServiceS } from '@/modules/cars/modules/sipp/sipp.service';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';

@Component({
    components: { CustomSelect },
})
export default class VehicleFilter extends Vue {
    @Inject(SippServiceS) private sippService!: SippService;
    @Inject(SippSettingsServiceS) private sippSettingsService!: SippSettingsService;

    get items(): Item[] {
        const { vehicleTypes } = this.sippService.filters;

        if (!vehicleTypes) {
            return [];
        }

        // currently support just 'VAN'
        const van = vehicleTypes[1]; // 'VAN'
        const anyVan = vehicleTypes[0]; // 'Any'

        return [
            { name: anyVan, value: anyVan },
            { name: 'Yes', value: van },
            { name: 'No', value: '-' },
        ];
    }

    get currentValue() {
        return this.sippSettingsService.vehicleValue;
    }

    set currentValue(value) {
        if (value) {
            this.sippSettingsService.vehicleValue = value;
        }
    }
}
