
import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
import { Inject } from 'inversify-props';
import { Switch } from 'element-ui';
import SippService, { SippServiceS } from '@/modules/cars/modules/sipp/sipp.service';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import SippLogItemModel from '@/modules/cars/modules/sipp/models/sipp-log-item.model';
import CustomSwitch from '@/modules/common/components/ui-kit/custom-switch.vue';
import _ from 'lodash';
import UserService, { UserServiceS } from '@/modules/user/user.service';

@Component({
    components: {
        CustomLoader,
        CustomSwitch,
        'el-switch': Switch,
    },
})
export default class SippHistoryBar extends Vue {
    @Inject(SippServiceS) private sippService!: SippService;
    @Inject(UserServiceS) private userService!: UserService;

    @Prop({
        type: Boolean,
        required: true,
        default: false,
    })
    isHistoryOpen!: boolean;

    checkedDate: number | boolean = false;

    handleClose() {
        this.$emit('closeHistory');
        this.checkedDate = false;
    }

    async handleClick(historyId: number, item: SippLogItemModel) {
        this.sippService.loading.start();
        this.checkedDate = historyId;
        this.sippService.logsChanges = item.changes;
        this.sippService.logVersion = item.version;
        this.sippService.showChangesOnly = false;
        await this.sippService.loadSIPPChanges();
        this.sippService.loading.finish();
    }

    async handleRestore(e: Event) {
        e.stopPropagation();
        this.sippService.historyLoading.start();
        await this.sippService.revertSippCode();
        this.sippService.historyLoading.finish();
        this.checkedDate = false;
    }

    // Disable toggle button when there's no version selected
    get isDisabled() {
        return !(this.sippService.logVersion);
    }

    get isOpen() {
        return this.isHistoryOpen;
    }

    get isReadonly() {
        return this.userService.isReadonly;
    }

    formatDate(date: Date) {
        return moment(date).format('MMMM D, YYYY hh:mm A');
    }

    get items(): SippLogItemModel[] {
        const data = this.sippService.sippLogs;
        if (!data) {
            return [];
        }
        return data;
    }

    get showChangesOnly() {
        return this.sippService.showChangesOnly;
    }

    set showChangesOnly(value: boolean) {
        this.sippService.showChangesOnly = value;
    }
}
