
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import SippService, { SippServiceS } from '@/modules/cars/modules/sipp/sipp.service';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import _ from 'lodash';
import { POS_ANY } from '@/modules/cars/constants/car-filter-types.constant';

@Component({
    components: { CustomSelect },
})
export default class CarProviderFilter extends Vue {
    @Inject(SippServiceS) private sippService!: SippService;
    @Inject(SippSettingsServiceS) private sippSettingsService!: SippSettingsService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(UserServiceS) private userService!: UserService;

    get items(): Item[] {
        const { providers } = this.sippService.filters;
        const { carProvidersDisplayNamesMap } = this.carsFiltersService;

        if (!providers) {
            return [];
        }

        if (!this.currentValue || !providers.find(carClass => carClass === this.currentValue)) {
            const [defaultPos] = providers;
            this.currentValue = defaultPos;
        }

        return providers.map(value => ({
            name: _.get(carProvidersDisplayNamesMap, value),
            value,
        }));
    }

    get currentValue() {
        return this.sippSettingsService.providersValue;
    }

    set currentValue(value) {
        if (value) {
            this.sippSettingsService.providersValue = value;

            const { posValue } = this.sippSettingsService;
            const { currentCompany } = this.userService;
            if (currentCompany === 'Hertz' && value === 'Enterprise' && posValue === 'BE') {
                this.sippSettingsService.posValue = POS_ANY;
            }
        }
    }
}
