
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import SippSettingsService, { SippSettingsServiceS } from '@/modules/cars/modules/sipp/sipp-settings.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { POS_ANY } from '@/modules/cars/constants/car-filter-types.constant';

@Component({
    components: { CustomSelect },
})
export default class PosFilter extends Vue {
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(SippSettingsServiceS) private sippSettingsService!: SippSettingsService;
    @Inject(UserServiceS) private userService!: UserService;

    get items(): Item[] {
        const { posCodes } = this.carsFiltersService.settings;
        const { providersValue } = this.sippSettingsService;
        const { currentCompany } = this.userService;

        return [POS_ANY, ...(posCodes || [])].reduce((acc: Item[], value: string) => {
            if (!(currentCompany === 'Hertz' && providersValue === 'Enterprise' && value === 'BE')) {
                acc.push({ value, name: value });
            }

            return acc;
        }, []);
    }

    get currentValue() {
        return this.sippSettingsService.posValue;
    }

    set currentValue(value) {
        if (value) {
            this.sippSettingsService.posValue = value;
        }
    }
}
