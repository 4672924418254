
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import SippService, { SippServiceS } from '@/modules/cars/modules/sipp/sipp.service';
import { ISippTableHead } from '@/modules/cars/modules/sipp/components/sipp.vue';
import CarNormalizedClassesFilter from '@/modules/cars/modules/sipp/components/sipp-filters/car-normalized-classes-filter.vue';
import FuelTypeFilter from '@/modules/cars/modules/sipp/components/sipp-filters/fuel-type-filter.vue';
import SippSearch from './sipp-filters/sipp-search.vue';
import ParityTransmissionFilter from './sipp-filters/transmission-filter.vue';
import ParityCarClassesFilter from './sipp-filters/car-classes-filter.vue';
import ParityPosFilter from './sipp-filters/pos-filter.vue';
import CarProviderFilter from './sipp-filters/car-provider-filter.vue';
import CountryFilter from './sipp-filters/country-filter.vue';
import VehicleFilter from './sipp-filters/vehicle-filter.vue';

@Component({
    components: {
        FuelTypeFilter,
        ParityPosFilter,
        ParityCarClassesFilter,
        ParityTransmissionFilter,
        SippSearch,
        CarNormalizedClassesFilter,
        CarProviderFilter,
        VehicleFilter,
        CountryFilter,
        CarBrandFilter: () => import('./sipp-filters/car-brand-filter.vue'),
    },
})
export default class SippActions extends Vue {
    @Inject(SippServiceS) private sippService!: SippService;

    @Prop({
        type: Array,
        required: true,
    })
    columns!: ISippTableHead[];

    @Prop({
        type: String,
        required: true,
    })
    hoverColumn!: string;

    get key() {
        return this.hoverColumn;
    }

    isHover(key: string) {
        if (!key) {
            return false;
        }
        return key === this.key;
    }

    get hideFilters() {
        return !this.sippService.showChangesOnly;
    }

    get carName() {
        return this.sippService.storeState.settings.carName || '';
    }

    updateCarName(query: string) {
        this.sippService.storeState.settings.carName = query;
    }

    get standardSipp() {
        return this.sippService.storeState.settings.standardCode || '';
    }

    updateStandardSipp(query: string) {
        this.sippService.storeState.settings.standardCode = query;
    }

    get customSipp() {
        return this.sippService.storeState.settings.customCode || '';
    }

    updateCustomSipp(query: string) {
        this.sippService.storeState.settings.customCode = query;
    }

    get categoryName() {
        return this.sippService.storeState.settings.categoryName || '';
    }

    get countryName() {
        return this.sippService.storeState.settings.countryName || '';
    }
}
